<template>
  <div>
    <div v-if="reservation" class="reservation">
      <img
        v-if="!usePlaceholder"
        :src="display.imageUrl"
        class="reservation-image"
        alt="trip location"
        @error="usePlaceholder = true"
      />
      <div v-else class="reservation-image-placeholder">
        <IconPlaceholderCityDark
          width="60%"
          height="70%"
          style="color: white"
        />
      </div>
      <div class="reservation-image-bg"></div>
      <div class="reservation-content">
        <AppNavButton class="reservation-nav" icon="close" @click="goHome" />
        <div class="container-xs reservation-text">
          <p style="font-family: GTSuper; font-size: 33px; line-height: 1rem">
            It's Happening!
          </p>
          <p
            style="
              padding: 0 2rem;
              font-size: 15px;
              text-shadow: var(--text-shadow-soft);
            "
          >
            Your trip to {{ display.destinationName }} is booked. Way to wander,
            friend.
          </p>
          <XButton style="width: 100%" type="large" @click="goToTrip"
            >VIEW TRIP</XButton
          >
        </div>
      </div>
    </div>
    <LoadingModalFullScreen
      ref="loadingModal"
      no-fade-in
      :text="message"
      non-closable
      loader="plane"
    />
    <InfoModalFullScreen
      ref="errorModal"
      title="Well this is a bummer."
      text="Looks like we’re having trouble processing trip reservations. Try again."
      cta="Try Again"
      @cta="$router.push('/search/summary')"
      @hide="$refs.cancelTripModal.show()"
    />
    <InfoModal
      ref="cancelTripModal"
      title="Are you sure?"
      text="You are about to cancel your trip"
      cta="CANCEL TRIP"
      @cta="$router.push('/search/form')"
      @hide="$refs.errorModal.show()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppNavButton from '../../components-rf/AppNavButton.vue'
import IconPlaceholderCityDark from '../../components-rf/icons/placeholders/IconPlaceholderCityDark.vue'
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import InfoModalFullScreen from '../../components-rf/modals/InfoModalFullScreen.vue'
import LoadingModalFullScreen from '../../components-rf/modals/LoadingModalFullScreen.vue'
import XButton from '../../components/common/global/XButton.vue'

export default {
  name: 'TripReservation',
  components: {
    LoadingModalFullScreen,
    InfoModalFullScreen,
    InfoModal,
    AppNavButton,
    XButton,
    IconPlaceholderCityDark,
  },
  created() {
    const { onStage, onSuccess, onError, onEnd } = this
    this.createReservation({ onStage, onSuccess, onError, onEnd })
  },
  mounted() {
    this.$refs.loadingModal.show()
  },
  data: () => ({ usePlaceholder: false, message: '', reservation: undefined }),
  computed: {
    display() {
      if (!this.reservation) return
      const itinerary = this.reservation.itinerary
      const destination = itinerary.destinationLocation
      const name = [
        destination.locationName,
        // destination.metadata?.regionName,
        destination.metadata?.countryIsoCode,
      ]
      return {
        destinationName: name.filter(el => !!el).join(', '),
        imageUrl: destination.imageUrl,
      }
    },
  },
  methods: {
    ...mapActions('SearchReservation', ['createReservation']),
    onStage(message) {
      this.message = message
    },
    onSuccess(reservation) {
      this.reservation = reservation
      this.$store.commit('SearchState/clearState')
      this.$store.commit('SearchStream/clearState')
    },
    onError() {
      this.$refs.errorModal.show()
    },
    onEnd() {
      this.$refs.loadingModal.hide()
    },
    goHome() {
      this.$router.replace('/home')
    },
    goToTrip() {
      // const bookingId = this.reservation.bookingId
      const eludeReference = this.reservation.eludeReference
      this.$router.replace(`/bookings/details?eluderef=${eludeReference}`)
    },
  },
}
</script>

<style scoped lang="scss">
.reservation {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
}
.reservation-image {
  position: absolute;
  @include m {
    filter: blur(4px);
  }
  width: 105%;
  height: 105%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.reservation-image-placeholder {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--color-icon-background-dark);
}
.reservation-image-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(#0000 30%, #000a 100%);
}
.reservation-content {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: flex-end;
  padding: 2rem;
  height: 100%;
}
.reservation-nav {
  justify-self: flex-end;
  align-self: flex-start;
}
.reservation-text {
  display: grid;
  gap: 3rem;
  text-align: center;
  color: white;
  align-self: center;
}
</style>
